.services-section {
  padding: 60px;
  max-width: 1320px;
  color: #f7f7f7;
  margin: auto;
  position: relative;
}

.maisprocurados-title{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.maisprocurados-title img{
  max-width: 50px !important;
  margin-bottom: 30px !important;

}
.services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.services-container .service {
  background-color: #2424241a;
  padding: 30px;
  border-radius: 8px;
  font-size: 18px;
  text-align: center;
  cursor: pointer !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  background-color: #2424241a;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 14px 28px #00000040, 0 10px 10px #00000038;
  border: 1px solid #3254fc;
  backdrop-filter: blur(12px);
  line-height: 1.3;
  z-index: 10;
}

.service:hover {
  background-color: #0a04341a;
}

.services-container .service h3 {
  font-size: 22px;
  font-weight: 100;
  letter-spacing: 0.07rem;
}

.services-container .service p {
  font-size: 18px;
  color: #f7f7f7da;
  line-height: 1.5;
}
.h3-diferente{
  color: #f25c05;
}

@media (max-width: 380px) {
  .img-coroa{
    max-width: 50px !important;
    margin-left:-40px !important;
  }
  
}