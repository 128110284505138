
.home-wrapper {
  background-color: #070733;
  position: relative;
  margin-top: 100px;

}

.shapes-wrapper {
  position: relative;
  margin: auto;
}

.home-container {
  min-height:800px;
  margin: auto;
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  background-image: url('../../assets/imgs/12.webp');

}


.half-image {
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 100;
  max-width:600px;
  position: relative;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.home-container img {
  z-index: 20;
  position: relative;
  max-width: 500px;
  opacity: 10;
  
  border-radius: 30px;
}

.home-content {
  margin-right: 400px;
  align-self: center;
  max-width: 540px;
  color: #f7f7f7;
}

.home-content h1 {
  font-size: 38px;
  font-family: "redhatBold";
  font-weight: 550;
  letter-spacing: 0.08em;
  line-height: 1.05;
  margin-bottom: 20px;

}

.home-content p {
  line-height: 1.4;
  font-size: 18px;
  max-width: 400px;
  margin-bottom: 20px;
}

.home-content a{
  margin-top: 15px;
  display: block;
  width: fit-content;
}

.half-image{
  position: relative;
  z-index: 0;
}

.half-image .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
}

.shape-small,
.shape-small-2,
.shape-small-1 {
  z-index: 10;
  position: absolute;
  border: 50%;
  background-color: #F25C05;
  filter: blur(140px);
}

.shape-small {
  width: 150px;
  height: 100px;
  top: 2rem;
  left: -2.5rem;
}

.shape-small-1 {
  width: 150px;
  height: 150px;
  bottom: -0.5rem;
  right: -8rem;
}

.shape-small-2 {
  width: 150px;
  height: 150px;
  bottom: -10rem;
  left: -5rem;
}

@media (max-width: 780px) {
  .home-wrapper {
margin-top: 81px;
  }
}

@media (max-width: 900px) {
  .half-image {
    margin: auto;
    margin-top: 60px;
    padding: 40px;
    border-radius: 30px;
    width: 100%;
  }
  .home-content{
    margin: auto;
  }

  .home-content{
    
  padding: 0 20px;
  }

  .home-container h1{
    font-size: 36px;
  }

  .home-container {
    flex-direction: column;
    padding: 80px 0px;
    padding-bottom: 0px;
  }
  .home-container {
    min-height: 800px;
    margin: auto; 
    display: flex;
    align-items: right;
    justify-content: right;
  }
  .home-container {
    background-position: 55%; 
  }


}


@media (max-width: 900px) {
  .home-container {
    background-position: 60%; 
  }
}