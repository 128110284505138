.blog-container {
  margin: 20px;
  margin-top: 90px;
}

.blog-post {
  background-color: #242424;
  color: #f7f7f7;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.blog-post-container{
  display: flex;
  gap: 20px;
  text-align: center;
}

.blog-post .main-btn-alt{
  color: #f7f7f7 !important;
}

.blog-container{
  text-align: center;
}

.blog-container .subtitulo{
  color: #f7f7f7;
  text-align: center;
  margin-bottom: 30px;
}

.blog-container h2{
  color: #f7f7f7;
  margin-bottom: 20px !important;
}

.post-date {
  color: #888;
  margin-bottom: 10px;
}

.blog-post p {
  margin-bottom: 10px;
}

.blog-post a {
  color: #007bff;
  text-decoration: none;
}

.blog-post a:hover {
  text-decoration: underline;
}
