.bullets div {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 18px;
}

.bullets i {
  background-color: #F25C05;
  color: #0c0c0c;
  border-radius: 100%;
  font-size: 18px;
}
.sidebar-socials {
  position: absolute;
  bottom: 50px;
  left: 50px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.sidebar-socials-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar-socials-content a {
  height: fit-content;
  line-height: 1;
  font-size: 25px;
 
}
.sidebar-socials-content i {
  color: #F25C05;
}
.bullets {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bullets p {
  margin-top: 0;
}

.sobre-content h4 {
  font-family: "barlow", sans-serif;
  font-size: 20px;
  margin-top: 30px;
}

.tornamos-wrapper {
  margin-top: 80px;
  margin-bottom: 80px;
}
@media (max-width: 850px) {
  .tornamos-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }


 
}
