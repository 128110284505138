.App {
  font-family: "redhatRegular", sans-serif;
}

button {
  font-family: "redhatRegular", sans-serif;
}

.body-wrapper {
  min-height: 80vh;
  background-color: #030335;
  padding-bottom: 80px;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-family: "redhatBold";
  letter-spacing: 0.02em;
}

@font-face {
  font-family: "barlow";
  src: url("./assets/font/Barlow-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "redhatBold";
  src: url("./assets/font/sequel-sans-black-head.ttf") format("truetype");
}

@font-face {
  font-family: "redhatRegular";
  src: url("./assets/font/RedHatDisplay-Regular.ttf") format("truetype");
}

h2 {
  text-transform: uppercase;
  text-align: center;
  font-family: "redhatBold";
  text-align: center;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 0.08em;
}

.main-btn {
  font-size: 18px;
  font-weight: 600;
  background: #F25C05 ;
  border-radius: 10px;
  padding: 15px 45px;
  color: #f7f7f7;
  font-family: "redhatRegular";
  cursor: pointer;
  box-shadow: 0 0 58px 0 rgba(3, 2, 46, 0.2);
  transition: 0.3s;

}

.main-btn:hover {
  color: #fff;
  transform: scale(1.05);
  background: linear-gradient(31.11deg, #030335 -1.31%, #F25C05 129.36%);
  box-shadow: 0 0 58px 0 rgba(3, 2, 46, 0.5);
}

.main-btn-alt {
  font-size: 18px;
  background: linear-gradient(31.11deg, #030335 -1.31%, #F25C05 129.36%);
  border-radius: 10px;
  padding: 15px 45px;
  color: #f7f7f7;
  font-family: "redhatRegular";
  cursor: pointer;
  box-shadow: 0 0 58px 0 rgba(3, 2, 46, 0.2);
  transition: 0.3s;
  border: 1px solid currentColor;
  border-radius: 10px;
  
}

.main-btn-alt:hover{
  scale: 1.1;
}

.main-btn {
  font-size: 18px;
  background: #f25c05c3;
  border-radius: 10px;
  padding: 15px 45px;
  color: #f7f7f7;
  font-family: "redhatRegular";
  cursor: pointer;
}

.main-btn-green {
  font-size: 18px;
  font-weight: 600;
  background-color: #f25c05c3 ;
  border-radius: 10px;
  padding: 15px 45px;
  color: #f7f7f7;
  font-family: "redhatRegular";
  cursor: pointer;
  transition: 0.3s !important;
  box-shadow: 0 0 58px 0 rgba(3, 2, 46, 0.2);

}
.main-btn-green:hover{
  text-decoration: none !important;
  transform: scale(1.05);
  background: linear-gradient(31.11deg, #03022e -1.31%, #F25C05 129.36%);
  box-shadow: 0 0 20px 0 rgba(3, 2, 46, 0.5);
}

.whats-btn a {
  z-index: 0;
  opacity: 1;
  z-index: 50;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  padding: 10px;
  border-radius: 99px;
  transition: 0.4s;
  font-size: 75px;
  background-color: #F25C05 ;
  color: #fff;
}

.whats-btn a:hover {
  background-color: #fff ;
  color:#F25C05 ;
}


@media (max-width: 450px){
  .sobre-container{
    padding: 0 20px !important;
  }

  .services-section{
    padding: 60px 20px !important; 
  }

  .testimonials-wrapper{
    padding: 40px 20px !important;
  }

  .porque-precisa-container{
    padding: 120px 20px !important;
  }
}

/* Fazer aqui um template, adiantando o setup abaixo: 

main-btn, secondary-btn e minor-btn;

h1 -> configurações de título: font, size, color

h2 -> configurações de subtítulo: font, size, color

*/
