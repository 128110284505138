.cta-container{
    background-image: url('../../assets/imgs/fundo2.png');
    color: #f7f7f7;
    text-align: center;
    background-position: center;
    font-size: 30px;
    padding: 90px 20px;
    margin-top: 80px;
}

.cta-container a{
    width: fit-content;
    margin: auto;
    margin-top: 10px;

}

.cta-container h2{
    margin-bottom: 15px;
}




.cta-content{
        display: flex;
    flex-direction: column;
    gap: 10px;
    margin: auto;
    max-width: 500px;
}