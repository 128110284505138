.porque-precisa-container {
  color: #f7f7f7;
  background-color: #030335;
  padding: 60px 60px;
  max-width: 1320px;
  margin: auto;
}

.shape-wrapper {
  position: relative;
}

.porque-precisa-container h2 {
  font-family: "redhatBold";
  letter-spacing: 0.08em;
  text-align: center;
  margin-bottom: 30px;
  font-size: 30px;
}

.porque-precisa-content ul div {
  margin-top: 20px;
  display: flex;
  gap: 30px;
  justify-content: center;
  text-align: center;
  font-size: 18px;
}
@media (max-width: 768px) {
  .porque-precisa-content ul div {
    flex-direction: column;
    align-items: center;
  }
}

.porque-precisa-content ul div li {
  flex: 1;
  max-width: 400px;
  background-color: #2424241a;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 14px 28px #00000040, 0 10px 10px #00000038;
  border: 1px solid #f25c05c3;
  backdrop-filter: blur(12px);
  line-height: 1.3;
  z-index: 10;
}


.porque-precisa-content strong {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 300;
  letter-spacing:0.10rem ;
}

.shape-small-grid {
  position: absolute;
  bottom: -2rem;
  right: 2rem;
  width: 200px;
  height: 180px;
  border: 50%;
  background-color: #02021c;
  filter: blur(90px);
}
