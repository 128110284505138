.faq-container {
  margin: 20px;
  padding: 70px 20px 60px 20px;
  color: #f7f7f7;
  max-width: 900px;
  margin: auto;
}

.faq-item {
  padding: 15px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #F25C05;
}

.faq-question button{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: #f7f7f7;
}

.faq-question span{
  background-color: #F25C05;
  color: black;
  font-size: 20px;
  padding: 2px 8px;
  border-radius: 5px;
}

.faq-item h3 {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.03em;
  margin-top: 0;
  line-height: 1.3;
}

.faq-item button p {
  font-size: 22px;
  color: #f7f7f7;
  text-align: left;
}

.faq-item p {
  margin-top: 10px;
    color: #f7f7f7da;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  letter-spacing: 0.05rem;
}

@media (max-width: 450px) {
  .faq-item h3{
    font-size: 18px;
  }

  .faq-item button p{
    font-size: 18px;
  }

  .faq-item p{
    font-size: 16px;

  }

}
@media (max-width: 750px) {
  .faq-item h3{
    font-size: 18px;
  }

  .faq-item button p{
    font-size: 18px;
  }

  .faq-item p{
    font-size: 16px;

  }
  .faq-container {
    margin: 20px;
    padding: 20px 20px 60px 20px;
    color: #f7f7f7;
    max-width: 900px;
    margin: auto;
  }

}