.testimonials-wrapper {
  overflow: hidden;
  width: 100%;
  background-color: #030335;
  color: #f7f7f7;
  padding: 80px 60px;
  max-width: 1320px;
  margin: auto;
}

.slick-list {
  padding-top: 2px !important;
}

.slick-list {
  margin: 0 -5px;
}
.slick-slide > div {
  padding: 0 5px;
}

.testimonials-container .carousel-item {
  margin: 10px;
}

.carousel-child {
  margin: 10px;
}

.testimonials-items {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
}

.testimonials-items i {
  font-size: 40px;
}

.testimonials-items div {
  flex: 1;
}

.testimonials-item {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  max-height: 315px;
}

.testimonials-container .custom-arrow {
  top: 265px;
}
