.sobre-wrapper {
  background-color: #030335;
  position: relative;
}

.sobre-container {
  color: #f7f7f7;
  max-width: 1320px;
 
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  position: relative;
}

.sobre-content{
  margin-left: 60px;
}

.sobre-img{
  max-width: 500px;
}
@media (max-width: 768px) {
  .sobre-container {
    flex-direction: column !important;
  }
}

.sobre-container.reverse {
  flex-direction: row-reverse;
}


.sobre-container div {
  flex: 1;
}
.sobre-content{
  margin-left: 20px;
}

.sobre-content p {
  font-size: 18px;
  line-height: 1.3;
  margin-top: 10px;
}

.sobre-container h3 {
  font-family: "barlow";
  font-size: 30px;
  margin-bottom: 15px;
  line-height: 1.15;
}

